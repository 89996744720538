import React from 'react';
import BackgroundAnimation from 'src/components/mobile/backgroundAnimation/backgroundAnimation';
import video from 'src/assets/videos/chapter_2/sub_2/2-2-1-bg__mobile.webm';
import videoMp4 from 'src/assets/videos/chapter_2/sub_2/2-2-1-bg__mobile.mp4';
import { BlockContainer, ChpaterTitle, LeninTitle } from '../../_styles';
import Quote from '../../../../../components/mobile/quote/quote';

const Slide = ({ query, title }) => (
  <BlockContainer height="860px">
    <BackgroundAnimation src={video} mp4={videoMp4} responsive>
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <LeninTitle>Lenin</LeninTitle>
      <Quote containerStyle={{ marginTop: '-18.5rem' }} fontColor="white" quoteText={query.allMdx.edges[0].node.body} quoteAuthor={query.allMdx.edges[0].node.frontmatter.author} />
    </BackgroundAnimation>
  </BlockContainer>
);

export default Slide;
