import React from 'react';
import Quote from 'src/components/desktop/quote/quote';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import Video from 'videos/chapter_2/sub_2/2-2-1-bg.webm';
import VideoMP4 from 'videos/chapter_2/sub_2/2-2-1-bg.mp4';
import { useStaticQuery, graphql } from 'gatsby';

import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    scenelayer1: file(relativePath: { eq: "chapter_2/sub_2/overlay-5-layers.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  modalImage: file(relativePath: { eq: "chapter_2/sub_2/2-2-1-modal.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  }
  }
  `);

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'rgba(19,35,46, 0.25)' }}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
        </Mordo>
        <Mordo
          scenelayer
          scene={2}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
          opacity={0.3}
        />
        <Mordo
          flexi
          scene={3}
          move={{ x: -20, y: -10 }}
        >
          <div css={{
            alignSelf: 'center', width: '100%', margin: '12% auto 0',
          }}
          >
            <Quote fontColor="white" quoteText={query.allMdx.edges[0].node.body} quoteAuthor={query.allMdx.edges[0].node.frontmatter.author} modalImage={assetQuery.modalImage.childImageSharp.fixed} />
          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
